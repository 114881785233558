<template>
  <CDropdown 
    :caret="false"
    placement="bottom-end"
    in-nav
    class="c-header-nav-item mx-2"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <CIcon name="cil-cart"/>
        <CBadge shape="pill" color="info">{{$store.state.cart ? $store.state.cart.length : 0}}</CBadge>
      </CHeaderNavLink>
    </template>
    <CDropdownItem 
      class="border-top text-center"
    >
      <div style="width:50%;float:left;text-align:center;">
        <CButton size="sm" color="default" @click="clearCart()">Clear Cart</CButton>
      </div>
      <div style="width:50%;float:left;text-align:center;">
        <CButton size="sm" color="danger" @click="$router.push('/checkout')">Checkout</CButton>
      </div>
    </CDropdownItem>
    <!-- <CDropdownHeader tag="div" class="text-center bg-light">
      <strong>You have {{itemsCount}} cart items</strong>
    </CDropdownHeader> -->
    <CDropdownItem v-if="$store.state.cart !== 0" v-for="(value, index) in $store.state.cart">
      <div class="message" style="width:300px;">
        <div class="pt-2 mr-3 float-left" style="width:80px;">
          <div class="c-avatar">
            Item #{{index + 1}}

            <!-- <span class="avatar-status bg-success"></span> -->
          </div>
        </div>
        <!-- <div>
          <small class="text-muted">John Doe</small>
          <small class="text-muted float-right mt-1">
            {{value.booking_style}} - {{value.booking_color}}
          </small>
        </div> -->
        <div class="pt-3 text-truncate font-weight-bold float-left" style="min-width:200px;">
          <!-- <CIcon name="cil-warning" class="text-danger"/>  -->
          {{value.customer_style}} - {{value.booking_color}} ({{value.quantity_ordered}})
        </div>
        <!-- <div class="small text-muted text-truncate">{{value.quantity_ordered}}</div> -->
      </div>
    </CDropdownItem>
    <CDropdownItem v-if="$store.state.cart === 0">
      <!-- <div style="width:100%;float:left;text-align:center;"> -->
        <small class="text-muted float-right mt-1">No Items In Cart</small>
      <!-- </div> -->
    </CDropdownItem>
  </CDropdown>
</template>
<script>
var methods = {}

methods.clearCart = function () {
  window.localStorage.removeItem('cart')
  this.$store.dispatch('setCart')
}

export default {
  name: 'TheHeaderDropdownMssgs',
  data () {
    return { itemsCount: '' }
  },
  methods: methods,
  mounted: function () {
    console.log(this.$store.state.cart)
    if (this.$store.state.cart) {
      this.itemsCount = this.$store.state.cart.length
    } else {
      this.itemsCount = 0
    }
  }
}
</script>
